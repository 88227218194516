// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-page-templates-landing-page-js": () => import("../src/PageTemplates/LandingPage.js" /* webpackChunkName: "component---src-page-templates-landing-page-js" */),
  "component---src-page-templates-profile-page-js": () => import("../src/PageTemplates/ProfilePage.js" /* webpackChunkName: "component---src-page-templates-profile-page-js" */),
  "component---src-page-templates-cart-page-js": () => import("../src/PageTemplates/CartPage.js" /* webpackChunkName: "component---src-page-templates-cart-page-js" */),
  "component---src-page-templates-order-detail-page-js": () => import("../src/PageTemplates/OrderDetailPage.js" /* webpackChunkName: "component---src-page-templates-order-detail-page-js" */),
  "component---src-page-templates-product-detail-page-js": () => import("../src/PageTemplates/ProductDetailPage.js" /* webpackChunkName: "component---src-page-templates-product-detail-page-js" */),
  "component---src-page-templates-video-list-page-js": () => import("../src/PageTemplates/VideoListPage.js" /* webpackChunkName: "component---src-page-templates-video-list-page-js" */),
  "component---src-page-templates-product-list-page-js": () => import("../src/PageTemplates/ProductListPage.js" /* webpackChunkName: "component---src-page-templates-product-list-page-js" */),
  "component---src-page-templates-course-page-js": () => import("../src/PageTemplates/CoursePage.js" /* webpackChunkName: "component---src-page-templates-course-page-js" */),
  "component---src-page-templates-register-page-js": () => import("../src/PageTemplates/RegisterPage.js" /* webpackChunkName: "component---src-page-templates-register-page-js" */),
  "component---src-page-templates-auth-result-page-js": () => import("../src/PageTemplates/AuthResultPage.js" /* webpackChunkName: "component---src-page-templates-auth-result-page-js" */),
  "component---src-page-templates-forget-password-page-js": () => import("../src/PageTemplates/ForgetPasswordPage.js" /* webpackChunkName: "component---src-page-templates-forget-password-page-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

